import { BaseError } from "@interfaces/common";
import { msg } from "@lingui/core/macro";
import { AxiosError } from "axios";

const DEFAULT_ERROR_MESSAGE = msg`Unexpected error`.message;

export default function parseRequestError(err: unknown) {
    if (err instanceof AxiosError) {
        const data = err.response.data as {
            errors: BaseError[];
        };

        const title = data?.errors[0]?.title || DEFAULT_ERROR_MESSAGE;
        const errorMessage = data.errors.map(error => error.detail).join(", ");

        return {
            title: `${title} (${err.response.status})`,
            description: errorMessage,
        };
    }

    if (err instanceof Error) {
        return {
            title: DEFAULT_ERROR_MESSAGE,
            description: err.message,
        };
    }
}
