import { i18n } from "@lingui/core";

export async function dynamicActivate(locale: string) {
    try {
        const { messages } = await import(`../locales/${locale}/messages`);

        i18n.loadAndActivate({ locale, messages });
    } catch (error) {
        throw new Error(error);
    }
}
