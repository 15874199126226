import { createStandaloneToast } from "@chakra-ui/react";
import { queryClientConfig } from "@lib/react-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import theme from "@theme/index";

type Props = { children: React.ReactNode };

const { ToastContainer, toast } = createStandaloneToast({ theme });
const queryClient = new QueryClient(queryClientConfig(toast));

export default function ReactQueryProvider({ children }: Props) {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
            <ToastContainer />
        </>
    );
}
