import { DEFAULT_LOCALE } from "@constants/common";
import { TenantThemeContext } from "@contexts/tenantTheme/TenantThemeContext";
import { dynamicActivate } from "@lib/i18n";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useContext, useEffect, useMemo, useState } from "react";

export default function LinguiProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [isLoading, setIsLoading] = useState(true);

    const tenant = useContext(TenantThemeContext);

    const locale = useMemo(
        () => tenant?.user?.languageCode || tenant?.locale || DEFAULT_LOCALE,
        [tenant]
    );

    useEffect(() => {
        async function loadTranslations() {
            if (locale) {
                await dynamicActivate(locale);
                setIsLoading(false);
            }
        }

        loadTranslations();
    }, [locale]);

    if (isLoading) return null;

    return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}
