import { defineStyle, defineStyleConfig, theme } from "@chakra-ui/react";

/* base style */
const baseStyle = defineStyle({
    px: "2",
    py: "0.5",
    rounded: "md",
    fontSize: "xxs",
    fontWeight: "bold",
    letterSpacing: 0.5,
});

/* variants */
const solid = defineStyle({
    bg: "primary.500",
    color: "white",
});

const status = defineStyle(props => {
    const { colorScheme } = props;

    return {
        px: "2",
        py: "0.5",
        bg: `${colorScheme}.50`,
        textColor: `${colorScheme}.600`,
        borderColor: `${colorScheme}.600`,
        letterSpacing: 1,
        fontSize: "xxs",
        fontWeight: "bold",
        borderWidth: "1px",
        borderRadius: "md",
    };
});

const light = defineStyle(props => {
    const { colorScheme } = props;

    return {
        bg: `${colorScheme}.50`,
        color: `${colorScheme}.700`,
        py: "1",
        px: "3",
        fontWeight: "bold",
        fontSize: "xs",
    };
});

const badgeTheme = defineStyleConfig({
    baseStyle,

    variants: {
        ...theme.components.Badge.variants,
        solid,
        status,
        light,
    },

    defaultProps: {
        variant: "solid",
    },
});

export default badgeTheme;
