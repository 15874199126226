import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme";
import Fonts from "theme/fonts";
import { TenantThemeProvider } from "@contexts/tenantTheme/TenantThemeProvider";
import "../theme/styles/nprogress.css";
import NProgress from "nprogress";
import Router from "next/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FormProvider } from "@contexts/formContext";
import "@lib/i18n";
import LinguiProvider from "providers/LinguiProvider";
import GoogleMapsProvider from "@providers/GoogleMapsProvider";
import ReactQueryProvider from "@providers/ReactQueryProvider";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? (page => page);

    return (
        <ReactQueryProvider>
            <ChakraProvider theme={theme}>
                <Fonts />
                <TenantThemeProvider>
                    <LinguiProvider>
                        <GoogleMapsProvider>
                            <FormProvider>
                                {getLayout(<Component {...pageProps} />)}
                            </FormProvider>
                        </GoogleMapsProvider>
                    </LinguiProvider>
                </TenantThemeProvider>
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </ReactQueryProvider>
    );
}

export default MyApp;
